body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
    --custom: "rgba(255, 203, 5, 1),rgba(236, 232, 218, 0.54)"
  
    /* --accent: var(--white); */
  
    margin: 0;
    padding: 0;
    color: var(--accent);
  }
 
#root {
  display: flex;
  align-items: center;
  justify-content: center;
}
  .box {
    width: 90px;
    height: 90px;
    margin-top: 43vh;
    
    background: var(--accent);
    background-image: url(../../Assets/Logo.png);
  }
  @media only screen and (max-width: 600px) {
    .box {
      width: 90px;
      height: 90px;
      margin-top: 40vh;
      
      background: var(--accent);
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../../Assets/Logo.png);
    }
  }