@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Croissant+One&family=Poppins:wght@400&family=Raleway:wght@500&family=Roboto:ital,wght@0,300;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@700&family=Playfair+Display:wght@500&family=Poppins:wght@700&family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@700&family=Playfair+Display:wght@500&family=Poppins:wght@700&display=swap");

.urdutext {
  font-family: "Noto Nastaliq Urdu", serif;
}
body {
  font-family: "Roboto";
}
.gridbackround {
  background-image: url(./Assets/background-lite-image.png);
}
.backGroundLite {
  background-image: url(./Assets/background-lite-image.png);
}
.section-image-screen {
  background-image: url(./Assets/section_bg_2.jpg);
  height: 80vh;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
@media only screen and (min-width: 600px) {
}

.background {
  background-image: url(./Assets/AboutBackGroundImage.jpg);
  height: 60vh;
  width: 100%;
  background-size: cover;
  object-fit: contain;
  background-color: #3130306b;
  background-blend-mode: darken;
}

.slider{
  height: 75vh;
  width: 100%;
  background-size: cover;
  object-fit: contain;
  background-color: #3130306b;
  background-blend-mode: darken;
}
.slider-bg-1{
  background-image: url(./Assets/background_1.jpg);
}
.slider-bg-2{
  background-image: url(./Assets/background_2.jpg);
}
.slider-bg-3{
  background-image: url(./Assets/background_3.jpg);
}

.slider-bg-4{
  background-image: url(./Assets/background_4.jpg);
}
.animate {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 2;
  }
  50% {
    opacity: 0.2;
  }
}
.scouts-mobilImage-1 {
  background-image: url(./Assets/slider-image-1.jpg);
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: darken;
  height: 70vh;
  width: 100٪;
  background-size: cover;
  object-fit: cover;
  font-family: "Noto Nastaliq Urdu", serif;
}
.scouts-mobilImage-3 {
  background-image: url(./Assets/slider-image-3.jpg);
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: darken;
  height: 70vh;
  width: 100٪;
  background-size: cover;
  object-fit: cover;
}
.scouts-mobilImage-4 {
  background-image: url(./Assets/slider-image-4.jpg);
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: darken;
  height: 70vh;
  width: 100٪;
  background-size: cover;
  object-fit: cover;
}
.PhoneInputInput {
  outline: none !important;
  color: #212529 !important;
}
.formInput {
  border-color: #9d9e9f !important;
}
@media screen and (max-width: 500px) {
  .background {
    height: 28vh;
    width: 100%;
  }
  
}
@media screen and (min-width: 700px) {
  .background {
    height: 60vh;
    width: 100%;
  }
}
